<template>
  <div
    class="start gradient-background"
    :class="{
      'no-start':
        item.status == 'done' ||
        item.status == 'graded' ||
        item.status == 'terminated',
      'gradient-background': !isClick,
      'gradient-background-disabled': isClick,
    }"
    @click="into(item)"
  >
    {{ item.statusType }}
  </div>
</template>
<script>
export default {
  name: "examinationBotton",
  props: ["item"],
  data() {
    return {
      isClick: false,
    };
  },
  methods: {
    into(item) {
      let examination = this.$storage.getStorage("examination") || [];
      let isOpen = false;
      if (examination == 1) {
        isOpen = true;
      }
      if (!isOpen) {
        // examinationList.push(item.examinationId);
        this.$emit("onClick", item);
      } else {
        this.$message.error("请勿重复打开考场页面");
      }
      // this.$storage.setStorage([
      //   {
      //     key: "examination",
      //     value: examinationList,
      //   },
      // ]);
    },
  },
  mounted() {},
};
</script>
<style lang="less"></style>
